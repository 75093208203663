import React from "react"
import Layout from "../components/Layout"
import UseCases from "../components/UseCases"

const product = () => {
  return (
    <Layout
      title="How AI can be used in Enterprise Data Quality"
      description="Real business examples where AI-driven data quality analysis has shown its strengths. They will give a good idea about how AI can help in real business scenarios."
      contentType="website"
    >
      <UseCases />
    </Layout>
  )
}

export default product
