import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UseCase from "./UseCase"

const getUseCases = graphql`
  query getUseCases {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/usecases//" } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            order
          }
          body
        }
      }
    }
  }
`

const UseCases = () => {
  const data = useStaticQuery(getUseCases)
  const cases = data.allMdx.edges
  // console.log(cases)

  return (
    <section className="default-section">
      <div className="default-center">
        {cases.map(({ node }, index) => {
          return <UseCase key={index} data={node} position={index}></UseCase>
        })}
      </div>
    </section>
  )
}

export default UseCases
