import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styles from "../css/UseCases.module.css"

const UseCase = ({ data, position }) => {
  //   const { title } = data.frontmatter
  const body = data.body
  return (
    <div
      className={
        position === 0 ? styles.case : `${styles.case} ${styles.caseSeparator}`
      }
    >
      <MDXRenderer>{body}</MDXRenderer>
    </div>
  )
}

export default UseCase
